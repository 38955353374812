import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Header from "../components/Header"
import SEO from "../components/seo"
import Newsletter from "../components/Newsletter"

const Sobre = ({ data, location }) => {
  const age = new Date().getFullYear() - 1995

  return (
    <Layout>
      <SEO title="Sobre | Leituras por Flavia Nunes" />
      <Header title="Sobre" subtitle="" />

      <p>
        Oi, eu sou a Flavia Nunes. Tenho {age} anos e moro no interior de Minas,
        mas nasci em Belo Horizonte. Eu trabalho com desenvolvimento de software
        e sempre tive a leitura como um hobbie. Com o passar dos anos, a chegada
        da universidade e do trabalho, eu acabei deixando os livros de lado.
      </p>

      <p>
        Por isso, em 2020, no meio de uma pandemia, eu decidi voltar à ler e
        criei o Leituras como uma forma de ter um espaço confortável para
        compartilhar esse hobbie e me motivar nessa nova jornada. Aqui vocês vão
        encontrar resenhas, listas de desejo, to be read, unboxing de livros
        novos e clubes de assinatura. Boas vindas.
      </p>

      <Newsletter className="in-page" />

      <h2>Entre em contato</h2>
      <p>
        Se você quer fazer um freela de desenvolvimento de software, trocar
        livros ou só jogar conversa fora, você pode entrar em contato comigo
        pelo e-mail flavianunesdev@gmail.com, pelo twitter em{" "}
        <a href="#twitter">@tweetsdafla</a> ou pelo instagram em{" "}
        <a href="#instagram">@instadafla</a>.
      </p>
    </Layout>
  )
}

export default Sobre

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
